import Story from '../chunks/StoryCard'
import { GetStoriesList, getArticleById } from '../../../api/Category.service'
import { useCallback, useEffect, useState } from 'react'
import Loader from '../../../components/Loader/Loader'
import SeeAllStories from '../../../components/SeeAllStories/SeeAllStories'
import { useLocation, useParams } from 'react-router-dom'
import Image from '../../../components/Image/Image'
import { useStoryContext } from '../../../context/StoryContext'
import '../Blog/blog.scss'

interface story {
  heroBanner: string
  title: string
  // Other properties if needed
}

const TaggedStoryPage = () => {
  const [storyArticle, setStoryArticle] = useState<story>()
  const { data, setData } = useStoryContext()
  const [status, setStatus] = useState<{ loading: boolean; error: string | null }>({
    loading: true,
    error: null,
  })

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const tag = searchParams.get('tag')
  const { id } = useParams()

  const fetchData = async () => {
    try {
      const res = await GetStoriesList()
      if (!res.error) {
        const newData = res?.data?.storyData || []
        setData(newData)
        setStatus({ loading: false, error: null })
      } else {
        setStatus({ loading: false, error: 'Error fetching data' })
      }
    } catch (error) {
      setStatus({ loading: false, error: 'An error occurred while fetching data' })
    } finally {
      setStatus({ loading: false, error: null })
    }
  }

  const fetchArticle = useCallback(async () => {
    try {
      const res: any = await getArticleById(id)
      if (!res?.error) {
        setStoryArticle(res?.data)
        setStatus({ loading: false, error: null })
      } else {
        setStatus({ loading: false, error: 'Error fetching data' })
      }
    } catch (error: any) {
      if (error?.response?.data?.error?.status === 403) {
        const res: any = await getArticleById(id, true)
        if (!res?.error) {
          setStoryArticle(res?.data)
        }
      }
      setStatus({ loading: false, error: 'An error occurred while fetching data' })
    } finally {
      setStatus({ loading: false, error: null })
    }
  }, [id])

  useEffect(() => {
    if (data.length > 0) {
      return
    } else {
      fetchData()
    }
  }, [])

  useEffect(() => {
    fetchArticle()
  }, [fetchArticle])

  const filteredData = data.filter((item: any) => {
    // Convert both the tag and item.tags to lowercase before comparison
    const lowercaseTag = tag?.toLowerCase()
    const lowercaseItemTags = item?.tags?.map((t: any) => t.toLowerCase()) || []

    // Check if the lowercaseTag is included in lowercaseItemTags
    return lowercaseItemTags.includes(lowercaseTag)
  })

  if (status.loading) {
    return <Loader />
  }

  if (status.error) {
    return <div>Error: {status.error}</div>
  }

  const tags = filteredData.map((item) => item?.tags)

  return (
    <>
      {storyArticle?.heroBanner && (
        <section className='home hero-section'>
          <div id='carouselExampleCaptions' className='carousel slide'>
            <div className='carousel-inner'>
              <div className='carousel-item active'>
                <Image src={storyArticle?.heroBanner} className='d-block w-100' alt='banner' />
                <div className='carousel-caption d-none d-md-block'>
                  <h1>{storyArticle?.title}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <div className='blog-wrapper'>
        <div className='blog-content-wrapper'>
          <div className='blog-content'>
            <div className='blog-content__item'>
              <SeeAllStories tags={tags} />
            </div>
            <div className='blog-content__item'>
              <h2 className='item-title'>All Stories</h2>
              <Story data={filteredData} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TaggedStoryPage

import CustomButton from '../../../components/Button/Button'
import SeeAllStories from '../../../components/SeeAllStories/SeeAllStories'
import { useParams } from 'react-router-dom'
import './storydetails.scss'
import Image from '../../../components/Image/Image'

const StoryDetails = () => {
  const params = useParams()
  const data = JSON.parse(params?.data ? params?.data : '')

  return (
    <>
      <div className='blog-details'>
        <h2 className='blog-story-heading'>{data?.title}</h2>
        <p className='blog-story-title'>{data?.subTitle}</p>
        <div className='spacer'></div>
        <div className='story-banner'>
          {data?.heroBanner && (
            <section className='home hero-section'>
              <div id='carouselExampleCaptions' className='carousel slide'>
                <div className='carousel-inner'>
                  <div className='carousel-item active'>
                    <Image src={data?.heroBanner} className='d-block w-100' alt='banner' />
                    <div className='carousel-caption d-none d-md-block'>
                      <h1>{data?.description}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>
        <p className='blog-story-detail top-40'>{data?.description}</p>
        <p>To learn more or read more or donate, click the button below.</p>

        <CustomButton title='Load More' onClick={() => console.log('Learn more')} />
      </div>

      <SeeAllStories tags={data?.tags} />

      {/* <StoryCategory stories={relatedStories} /> */}
    </>
  )
}

export default StoryDetails

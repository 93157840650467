import { Outlet } from 'react-router-dom'
import Header from '../layout/Header/Header'
import Footer from '../layout/Footer/Footer'
import '../../style/style.scss'
import { useEffect, useRef } from 'react'
import { getMenus } from '../../api/menus.service'
import { useMenuContext } from '../../context/MenuContext'
import ErrorBoundary from '../../components/ErrorBoundry/ErrorBoundry'

const Desktop = () => {
  let { setmenu, setLoader } = useMenuContext()
  let checkApiCall = useRef<boolean>(false)
  useEffect(() => {
    if (!checkApiCall?.current) {
      const getData = async () => {
        try {
          setLoader(true)
          let result: any = await getMenus()
          if (result?.error?.response?.data?.error?.status === 403) {
            result = await getMenus(true)
            if (result?.data) {
              setmenu(result?.data?.menu)
            }
          }
          if (result?.data) {
            setmenu(result?.data?.menu)
          }
          setLoader(false)
        } catch (error: any) {
          setLoader(false)
        }
      }
      getData()
      checkApiCall.current = true
    }
  }, [checkApiCall, setmenu])

  return (
    <div>
      <Header />
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
      <Footer />
    </div>
  )
}

export default Desktop

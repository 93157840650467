// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.story-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.story-wrapper .story {
  display: flex;
  max-width: 344px;
  flex-direction: column;
  align-items: center;
  margin-right: 0;
  justify-content: center;
  cursor: pointer;
}
.story-wrapper .story__content {
  text-align: center;
}
.story-wrapper .story img {
  width: 100%;
}
.story-wrapper h3 {
  margin-top: 24px !important;
}

@media screen and (max-width: 1200px) {
  .story-wrapper .story {
    justify-content: flex-start;
  }
  .story-wrapper h3 {
    margin-top: 24px !important;
    font-size: 28px !important;
  }
}
@media screen and (max-width: 767px) {
  .story-wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .story-wrapper h3 {
    margin-top: 24px !important;
    margin-bottom: 15px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Stories/chunks/storycategory.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kCAAA;EACA,SAAA;EACA,gBAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,gBAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,uBAAA;EACA,eAAA;AACJ;AACI;EACE,kBAAA;AACN;AAEI;EACE,WAAA;AAAN;AAKE;EACE,2BAAA;AAHJ;;AAOA;EAEI;IACE,2BAAA;EALJ;EAQE;IACE,2BAAA;IACA,0BAAA;EANJ;AACF;AAUA;EACE;IACE,8BAAA;EARF;EAUE;IACE,2BAAA;IACA,8BAAA;EARJ;AACF","sourcesContent":[".story-wrapper {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr;\n  gap: 24px;\n  margin-top: 20px;\n  margin-bottom: 40px;\n\n  .story {\n    display: flex;\n    max-width: 344px;\n    flex-direction: column;\n    align-items: center;\n    margin-right: 0;\n    justify-content: center;\n    cursor: pointer;\n\n    &__content {\n      text-align: center;\n    }\n\n    img {\n      width: 100%;\n       \n    }\n  }\n\n  h3 {\n    margin-top: 24px !important;\n  }\n}\n\n@media screen and (max-width: 1200px) {\n  .story-wrapper {\n    .story {\n      justify-content: flex-start;\n    }\n\n    h3 {\n      margin-top: 24px !important;\n      font-size: 28px !important;\n    }\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .story-wrapper {\n    grid-template-columns: 1fr 1fr;\n\n    h3 {\n      margin-top: 24px !important;\n      margin-bottom: 15px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

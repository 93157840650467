import React, { useState } from 'react'
import { getAttendeeFormField, inputHandler } from '../../helper/utils'
import { attendanceTable, formFields } from '../../helper/constant'
import { string } from '../../helper/constant'
const AttendanceForm = () => {
  const [data, setdata] = useState({
    first_name: '',
    last_name: '',
    email_address: '',
    receive_updates: false,
    dream_note: '',
  })

  const changeHandler = (e: any, item: any) => {
    inputHandler(setdata, e, item.name, item.inputType)
  }

  return (
    <section className='donation-info-section light-pink-bg'>
      <div className='container'>
        <div className='row'>
          <div className='form'>
            <div className='information-form-secton'>
              <h2>Attendee Information</h2>
              <p>{string}</p>

              {formFields.map((item) => getAttendeeFormField(item, changeHandler))}
            </div>
          </div>

          <div className='table-responsive'>
            <table className='table'>
              <tbody>
                {attendanceTable.map((item) => {
                  return (
                    <tr>
                      <td>{item.price}</td>
                      <td>{item.gameDetails}</td>
                      <td>{item.availability}</td>
                      <td>{item.quantity}</td>
                      <td>{item.specialInfo}</td>
                      <td>{item.totalAmount}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>

            <div className='note'>
              <p>{string}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AttendanceForm

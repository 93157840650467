import React, { useEffect, useState } from 'react'
import { GetStoriesList } from '../../../api/Category.service'
import Story from '../chunks/StoryCard'
import StoryCategory from '../chunks/StoryCategory'
import { useMenuContext } from '../../../context/MenuContext'
import { useStoryContext } from '../../../context/StoryContext'
import { ourYoungPeople, newYorkKnicks, newYorkRangers } from '../../../helper/iconPath'
import './blog.scss'

const Blog = () => {
  const { data, setData } = useStoryContext()
  const { menu } = useMenuContext()
  const [status, setStatus] = useState<{ loading: boolean; error: string | null }>({
    loading: true,
    error: null,
  })

  const images = [
    {
      image: ourYoungPeople,
      title: 'Spotlight on Our Young People',
    },
    {
      image: newYorkKnicks,
      title: 'New York Knicks',
    },
    {
      image: newYorkRangers,
      title: 'New York Rangers',
    },
  ]

  const fetchData = async () => {
    try {
      const res = await GetStoriesList()
      if (!res?.error) {
        const newData = res?.data?.storyData
        setData(newData)
        setStatus({ loading: false, error: null })
      } else {
        setStatus({ loading: false, error: "'Error fetching data'" })
      }
    } catch (error) {
      setStatus({ loading: false, error: `An error occurred while fetching data` })
    } finally {
      setStatus({ loading: false, error: null })
    }
  }
  useEffect(() => {
    if (data.length === 0) {
      fetchData()
    }
  }, [])

  const temp = menu?.find((item) => item?.category === 'our-stories')

  const sortedArray = temp?.subCategory
    .sort((a: any, b: any) => a?.nav_order - b?.nav_order)
    ?.slice(1)

  const modifiedArray = sortedArray?.map((item: any, index: any) => ({
    ...item,
    image: images[index]?.image, // Assign a different image value for each item
  }))

  if (status.error) {
    return <div>Error: {status.error}</div>
  }

  return (
    <div className='blog-wrapper'>
      <StoryCategory stories={modifiedArray} />

      <div className='blog-content-wrapper'>
        <div className='blog-content'>
          <div className='blog-content__item'>
            <h2 className='item-title'>All Stories</h2>
            <Story data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blog

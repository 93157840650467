import React, { useEffect, useState } from 'react'
import './header.scss'
import Dropdown from './Dropdown/Dropdown'
import HeaderBtn from './HeaderBtn/HeaderBtn'
import { NavbarItems } from './NavbarItems/NavbarItems'
import { useLocation, useParams } from 'react-router-dom'
import { useWindowSize } from '../../../hooks/useWindowSize'
import { getInitialVal } from '../../../helper/utils'
import NavImgWrapper from './NavbarItems/NavImgWrapper'

const Header: React.FC = () => {
  let params = useParams()
  const [navActive, setnavActive] = useState<any>(getInitialVal(params.id))
  const [subActiveNav, setsubActiveNav] = useState(getInitialVal(params.subid))
  const [navMobile, setnavMobile] = useState(false)
  const location = useLocation()
  let width = useWindowSize()

  useEffect(() => {
    if (location.pathname === '/' && width > 1200) {
      // to set  parent and dropdown nav unselected on going to homepage
      setnavActive(null)
      setsubActiveNav(null)
    }
  }, [location, width])

  return (
    <div>
      <header>
        <div className='header'>
          <nav className='navbar navbar-expand-xl blue-bg'>
            <div className='container'>
              <NavImgWrapper setnavMobile={setnavMobile} navMobile={navMobile} />
              <div
                className={` navbar-collapse ${navMobile === true ? 'show' : 'collapse'}`}
                id='navbarScroll'
              >
                <div className=''>
                  <ul className='navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll'>
                    <NavbarItems
                      setnavActive={(activeNav) => {
                        // setsubActiveNav(null)
                        setnavActive(activeNav)
                      }}
                      navActive={navActive}
                      setsubActiveNav={setsubActiveNav}
                      setnavMobile={setnavMobile}
                    />
                  </ul>
                </div>
              </div>
              <HeaderBtn />
            </div>
          </nav>
        </div>
      </header>
      <Dropdown
        navIndex={navActive}
        subActiveNav={subActiveNav}
        setsubActiveNav={setsubActiveNav}
        isMobile={navMobile}
        setnavActive={setnavActive}
        setnavMobile={setnavMobile}
      />
    </div>
  )
}

export default Header

import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { downArrow  } from '../../../../helper/iconPath'
import { useEffect, useState } from 'react'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import Dropdown from '../Dropdown/Dropdown'
import { useMenuContext } from '../../../../context/MenuContext'
import { useStoryContext } from '../../../../context/StoryContext'
import Loader from '../../../../components/Loader/Loader'

export const NavbarItems: React.FC<NavbarItemsProps> = ({
  setnavActive,
  navActive,
  setsubActiveNav,
  setnavMobile,
}) => {
  const [navClick, setnavClick] = useState<any>(null)
  let location = useLocation()
  let isContentPage = location.pathname.includes('content/')
  let width = useWindowSize()
  let isMobile = width < 1200
  let { menu, loader } = useMenuContext()
  const navigate = useNavigate()
  const { id } = useParams()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { active, setActive } = useStoryContext()

  useEffect(() => {
    if (isMobile && navActive === undefined) {
      setnavActive(menu[0]?.uid)
    }
  }, [])

  useEffect(() => {
    if (isMobile && navActive === null) {
      setnavActive(menu[0]?.uid)
    }
  }, [width, navActive, setnavActive, isMobile, menu])

  useEffect(() => {
    if (menu?.length) {
      let navId = menu?.find((item) => item?.uid === id)

      let subNavId = menu?.find((item) =>
        item?.subCategory?.find((nitem: any) => nitem?.uid === id),
      )

      if (subNavId) {
        setsubActiveNav(id)
        setnavActive(subNavId.uid)
      }
      if (navId) {
        setnavActive(id)
        setsubActiveNav(null)
      }
    }
  }, [menu])

  useEffect(() => {
    if (active) {
      setsubActiveNav(active)
    }
  }, [active])

  const clickHandler = (uid: any) => {
    const temp = menu?.find((item) => item?.category === 'our-stories')

    // for toggle of the dropdown on homepage and not on content page
    navClick === uid ? setnavClick(null) : setnavClick(uid)
    if (isMobile) {
      setnavActive(uid)
    } else {
      uid === navActive && !isContentPage ? setnavActive(null) : setnavActive(uid)
      navigate(`/content/${uid}`)
    }
    if (uid === temp?.uid) {
      // setnavActive(temp?.uid)
      const blog = temp?.subCategory[0]?.uid

      navigate(`/content/${blog}`)
    }
  }

  const sortedMenu = menu?.sort((a, b) => a?.nav_order - b?.nav_order)

  if (loader) {
    return <Loader />
  }

  return (
    <>
      {sortedMenu?.map((item: any, index: number) => {
        return (
          <div className='navBar__item'>
            <li className='nav-item' key={index}>
              <button
                className={`nav-link ${item?.uid === navActive && 'navActive'}`}
                onClick={() => clickHandler(item?.uid)}
              >
                {item?.title}
                <img
                  src={downArrow}
                  alt='icon'
                  className={`m-show  mobile-arrow ${
                    navClick === item?.uid && 'mobile-arrow__open'
                  }`}
                />
              </button>
            </li>

            {isMobile && navClick === item?.uid && (
              <Dropdown
                navIndex={item?.uid}
                isMobile={isMobile}
                setsubActiveNav={setsubActiveNav}
                setnavMobile={setnavMobile}
                setnavActive={setnavActive}
              />
            )}
          </div>
        )
      })}

      <div className='header-contact-form'>
        <h5>Contact Us</h5>
        <p>Stays up-to-date on the latest at Garden of Dreams Foundation.</p>
        <div className='signup-section'>
          <input type='text' placeholder='Sign Up' />
          <a href='#dfs' className='btn btn-orange'>
            Sign Up
          </a>
        </div>
      </div>
    </>
  )
}

type NavbarItemsProps = {
  setnavActive: (active: any) => void
  navActive: any
  setsubActiveNav: (active: any) => void
  setnavMobile: (active: any) => void
}

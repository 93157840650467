// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-details {
  max-width: 1080px;
  padding: 40px 0;
  align-items: center;
  gap: 40px;
  background: var(--gdf-neutral-white, #fff);
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  margin: 0 auto;
}
.blog-details h2 {
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
  color: var(--gdf-neutral-dark-grey, #222);
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: 46px;
}
.blog-details p {
  color: var(--gdf-neutral-dark-grey, #222);
  /* GDF Body 1 */
  font-family: Brandon Grotesque;
  font-size: 24px;
  font-style: normal;
  font-weight: 420;
  line-height: 140%;
}
.blog-details .spacer {
  display: flex;
  height: 2px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  background: #faa946;
  margin: 40px 0px;
}
.blog-details .story-banner {
  width: 100%;
  height: auto;
}
.blog-details .story-banner img {
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .blog-details {
    padding: 40px 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Stories/StoryDetails/storydetails.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,eAAA;EACA,mBAAA;EACA,SAAA;EACA,0CAAA;EACA,mCAAA;EACA,2BAAA;EACA,cAAA;AACF;AACE;EACE,SAAA;EACA,UAAA;EACA,oBAAA;EACA,yCAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AAEE;EACE,yCAAA;EACA,eAAA;EACA,8BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;AAGE;EACE,aAAA;EACA,WAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;EACA,gBAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;AAFJ;AAII;EACE,YAAA;EACA,WAAA;AAFN;AAWA;EAEE;IACE,kBAAA;EAVF;AACF","sourcesContent":[".blog-details {\n  max-width: 1080px;\n  padding: 40px 0;\n  align-items: center;\n  gap: 40px;\n  background: var(--gdf-neutral-white, #fff);\n  -webkit-backdrop-filter: blur(25px);\n  backdrop-filter: blur(25px);\n  margin: 0 auto;\n\n  h2 {\n    margin: 0;\n    padding: 0;\n    padding-bottom: 20px;\n    color: var(--gdf-neutral-dark-grey, #222);\n    font-size: 42px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 46px;\n  }\n\n  p {\n    color: var(--gdf-neutral-dark-grey, #222);\n    /* GDF Body 1 */\n    font-family: Brandon Grotesque;\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 420;\n    line-height: 140%;\n  }\n\n  .spacer {\n    display: flex;\n    height: 2px;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 20px;\n    align-self: stretch;\n    background: #faa946;\n    margin: 40px 0px;\n  }\n\n  .story-banner {\n    width: 100%;\n    height: auto;\n\n    img {\n      height: 100%;\n      width: 100%;\n    }\n  }\n\n  .details-learn-more {}\n}\n\n\n\n@media screen and (max-width: 1200px) {\n\n  .blog-details {\n    padding: 40px 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import Router from './router'
import './style/leadership.scss'
import './style/directors.scss'
import './style/partners.scss'
import { CategoryContextProvider } from './context/Context'
import { MenuContextProvider } from './context/MenuContext'
import { ArticleContextProvider } from './context/ArticleContext'
import './App.scss'
import './style/style.scss'
import { StoryContextProvider } from './context/StoryContext'
import { Helmet, HelmetProvider } from 'react-helmet-async'

function App() {
  return (
    <HelmetProvider>

    <StoryContextProvider>
      <ArticleContextProvider>
        <CategoryContextProvider>
          <MenuContextProvider>
            <div className='App'>
              <Router />
            </div>
          </MenuContextProvider>
        </CategoryContextProvider>
      </ArticleContextProvider>
    </StoryContextProvider>
    </HelmetProvider>
  )
}

export default App

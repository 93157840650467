import React from 'react'
import { alert } from '../../helper/iconPath'
import './nodatafound.scss'

const NoDataFound = () => {
  return (
    <div className='nodatafound'>
        <img src={alert} alt="nodatafound" />
        <div>No data found</div>
    </div>
  )
}

export default NoDataFound
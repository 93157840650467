// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-load-more {
  display: flex;
  height: 48px;
  padding: 8px 26px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  background: #faa946;
  font-family: Brandon Grotesque bold !important;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/button.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EACA,mBAAA;EACA,8CAAA;AACF","sourcesContent":[".view-load-more {\n  display: flex;\n  height: 48px;\n  padding: 8px 26px;\n  justify-content: center;\n  align-items: center;\n  gap: 10px;\n  border: none;\n  background: #faa946;\n  font-family: Brandon Grotesque bold !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagenotfound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  font-size: 2rem;
}
.pagenotfound img {
  height: 7rem;
  width: 7rem;
  margin-bottom: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/PageNotFound/pagenotfound.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,aAAA;EACA,eAAA;AACJ;AAAI;EACI,YAAA;EACA,WAAA;EACA,mBAAA;AAER","sourcesContent":[".pagenotfound{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    height: 100vh;\n    font-size: 2rem;\n    img{\n        height: 7rem;\n        width: 7rem;\n        margin-bottom: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import Desktop from '../pages/Desktop/Destop'
import Home from '../pages/home/Home'
import PageNotFound from '../components/PageNotFound/PageNotFound'
import Category from '../pages/layout/Category/Category'
import StoryDetails from '../pages/Stories/StoryDetails/StoryDetails'
import TaggedStoryPage from '../pages/Stories/TaggedStoryPage/TaggedStoryPage'

export const routes = [
  {
    path: '/',
    element: <Desktop />,
    routes: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'content/:id',
        element: <Category />,
      },
      { path: '/stories/:id', element: <TaggedStoryPage /> },
      {
        path: '/details/:data',
        element: <StoryDetails />,
      },
    ],
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]

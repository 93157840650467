import { useNavigate } from "react-router"
import { gdfLogo, hamburger } from "../../../../helper/iconPath"

const NavImgWrapper: React.FC<navImgProps> = ({ setnavMobile, navMobile }) => {
  const navigate = useNavigate()
  return (
    <>
      <button className='navbar-brand' onClick={() => navigate('/')}>
        <img src={gdfLogo} alt='logo' />
      </button>
      <button className='navbar-toggler' type='button' onClick={() => setnavMobile(!navMobile)}>
        <span className='navbar-toggler-icon'>
          <img src={hamburger} alt='' />
        </span>
      </button>
    </>
  )
}

type navImgProps = {
  setnavMobile: (active: any) => void
  navMobile: boolean
}

export default NavImgWrapper
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getHtml, getMetaData } from '../../../helper/utils'
import { getArticleById } from '../../../api/Category.service'
import { useCategoryContext } from '../../../context/Context'
import Loader from '../../../components/Loader/Loader'
import '../../../style/style.scss'
import AttendanceForm from '../../../components/Attendance/AttendanceForm'
import Donate from '../../Donate/donate'
import Blog from '../../Stories/Blog/Blog'
import Image from '../../../components/Image/Image'
import NoDataFound from '../../../components/NoDataFound/NoDataFound'
import { landingId } from '../../../helper/constant'
import { Helmet } from 'react-helmet-async'

const Category: React.FC = () => {
  const [data, setdata] = useState<any>()
  const { id } = useParams()
  const { category, setcategory } = useCategoryContext()
  const [loader, setloader] = useState(true)
  const navigate = useNavigate()
  const [uid, setUid] = useState('')

  useEffect(() => {
    if (!id || id === undefined) {
      setUid(landingId)
    } else {
      setUid(id)
    }
  }, [id, navigate])

  useEffect(() => {
    if (category?.length > 0) {
      setdata(category?.find((item) => item?.uid === uid))
    }
    const getData = async () => {
      setloader(true)
      try {
        if (category?.findIndex((item) => item?.uid === uid) === -1) {
          let result: any = await getArticleById(uid)

          if (!!result && !result?.error && result?.data) {
            setcategory((prev: any) => [...prev, result?.data])
          }
        }
      } catch (error: any) {
        if (error?.response?.data?.error?.status === 403) {
          let result: any = await getArticleById(uid, true)
          if (!!result && !result.error && result?.data) {
            setcategory((prev: any) => [...prev, result?.data])
          }
        }
        console.error('Error fetching data:', error)
        setloader(false)
      } finally {
        setloader(false)
      }
    }

    getData()
  }, [uid, category, setcategory, navigate])

  useEffect(() => {
    $('#carouselSlider').ready(function () {
      $('.owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        responsiveClass: true,
        dots: true,
        dotsEach: 3,
        responsive: {
          0: {
            items: 1,
            nav: true,
            loop: true,
            dots: true,
          },
          600: {
            items: 3,
            nav: false,
            loop: true,
            dots: true,
          },
          1000: {
            items: 3,
            nav: false,
            loop: true,
            dots: true,
          },
        },
      })
    })
  }, [uid, category, setcategory])

  const getComponent = () => {
    const components: React.ReactNode[] = []

    const item = category?.find((item) => item?.uid === id)
    const title = item?.title

    // Define a mapping of titles to components
    const titleToComponent: Record<string, React.ReactNode> = {
      Blog: <Blog />,
      Donate: <Donate />,
      'Dream notes New york knicks': <AttendanceForm />,
      'Dream notes NY rangers': <AttendanceForm />,
      // Add more titles and corresponding components here
    }

    // Check if the title exists in the mapping and push the component if found
    if (title && titleToComponent[title]) {
      components.push(titleToComponent[title])
    }

    return components
  }

  if (uid === 'donate') {
    return <Donate />
  }

  if (loader && !data) {
    return (
      <div className='loader'>
        <Loader />
      </div>
    )
  }
  return (
    <div className='category'>

      {data && !loader ? (

        <>
          <Helmet>
            {getMetaData(data)}
          </Helmet>
          {data?.heroBanner && (
            <section className='home hero-section'>
              <div id='carouselExampleCaptions' className='carousel slide'>
                <div className='carousel-inner'>
                  <div className='carousel-item active'>
                    <Image src={data?.heroBanner} className='d-block w-100' alt='banner' />
                    <div className='carousel-caption d-none d-md-block'>
                      <h1>{data?.title}</h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          {data?.content && <div dangerouslySetInnerHTML={getHtml(data?.content)} />}
          {getComponent()}
        </>
      ) : (
        <NoDataFound />
      )}
    </div>
  )
}

export default Category

export const headerBtn = [
  {
    text: 'SIGN UP',
    class: 'btn-orange',
    onclick: () => (window.location.href = '/content/contact'),
  },
  {
    text: 'Donate',
    class: 'btn-blue',
    onclick: () => (window.location.href = '/content/donate'),
  },
]

import { useNavigate } from 'react-router-dom'
import CustomButton from '../../../components/Button/Button'
import './storycategory.scss'
import { useStoryContext } from '../../../context/StoryContext'
import Image from '../../../components/Image/Image'

const StoryCategory: React.FC<CategoryProps> = ({ stories }) => {
  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { active, setActive } = useStoryContext()

  const onClick = (item: any) => {
    navigate(`/stories/${item?.uid}/?tag=${item?.title}`)
    setActive(item?.uid)
  }

  return (
    <div className='light-pink-bg'>
      <div className='category-container'>
        <h2 className='item-title'>Featured Stories by Category</h2>
        <div className='story-wrapper'>
          {stories?.map((item: any, index: any) => {
            return (
              <div className='story' key={index}>
                <Image src={item?.image} alt='story-banner' className='story__image' />
                <h3 className='story__title'>{item?.title}</h3>
                <CustomButton title='View more' onClick={() => onClick(item)} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

type CategoryProps = {
  stories: any
}

export default StoryCategory

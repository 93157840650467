import { notfound } from "../../helper/iconPath"
import "./pagenotfound.scss"

const PageNotFound = () => {
    return <div className="pagenotfound">
        <img src={notfound} alt="" />
        <div>Page Not Found</div>
    </div>
}

export default PageNotFound
const imgPath="/images";

export const hamburger = `https://drive.google.com/uc?id=1N_lPOTD8tRdqNkmv0QpMIB94D5Rcp6ZM`;
export const gdfLogo = `https://drive.google.com/uc?id=1IHEa_tpeDu8RxRhugOGlklppwVyKynGT`;
export const iconCall = `https://drive.google.com/uc?id=1mfr28SGg6K8PLB8pZdlOI1EEZXPHW0MP`;
export const iconEmail = `https://drive.google.com/uc?id=1QRlhbZo_I-KuzqHkE9JXEZIlELA1MbVD`;
export const iconFb = `https://drive.google.com/uc?id=11mYIaqG2f-bmxzAY9WrodbTQyAOB9_AW`;
export const iconIg = `https://drive.google.com/uc?id=10QRxsovMQZ6bC41S-2KpBpFqeWbP4LhH`;
export const iconLkdn = `https://drive.google.com/uc?id=1Qi02KfEnqVs3Wwe_jWUTseRNmy_raMLz`;
export const iconTktok = `https://drive.google.com/uc?id=1CAU8FJOv6ln2ht5XdtbHdTbk3Y-NgiyH`;
export const iconTwitter = `https://drive.google.com/uc?id=10dNBQt7GhTdA_v_QZ2TqdGUuZdMDnmTV`;
export const iconYoutube = `https://drive.google.com/uc?id=14qpWRZEKJCW93F_N6JVhfy8qX8x8yqTs`;
export const downArrow = `https://drive.google.com/uc?id=1rcijD07NVXCAlHxoO4oCuis9wei5mUhX`;
export const iconDreamNotes = `https://drive.google.com/uc?id=1MAQiDtxZbWUdUlrwG6qWIsi_SUV-PExb.png`;
export const iconDonateTickets = `https://drive.google.com/uc?id=1bxXY7LSr0-wisYnu2s4Hq_c2GyNHXduj.png`;
export const iconSponsorship = `https://drive.google.com/uc?id=1BcHfka2UJD8SnUX8cSucmOZU0Did6EOr.png `;
export const iconDonate = `https://drive.google.com/uc?id=1QAsn0RMTo-4PwfScZaXKa7sIoOjcYjvX.png`;
export const iconDonor = `https://drive.google.com/uc?id=1I7DW_4JIZDhHJbhIzRdnQTmDb9sY_H75.png`;
export const iconMarathon = `https://drive.google.com/uc?id=143-GvPfKDuwrllwCAML8hW21cI0Nc7U0.png`;
export const iconKnickBlue = `https://drive.google.com/uc?id=18MpbMvU-RtE7v3PJHbc6PSppJ2fpWH3a.png`;
export const iconNewYorkRanger = `https://drive.google.com/uc?id=1mrlU3Fkv0M8fOtP1zBydnyEA_thhIsbP.png`;
export const storyBlogBanner = `https://drive.google.com/uc?id=1NkL0NL_RZXChCfPdsE1MsFZTbzqtkZHR`
export const ourYoungPeopleBanner = `https://drive.google.com/uc?id=1HtkkBFpaP1G6w9LooA1ipxuvWFCAVox-`
export const newYorkKnicks = `https://drive.google.com/uc?id=1IgS4NEg6Eha7MFUD7cwgMREeyjXUaZBL`
export const newYorkRangers = `https://drive.google.com/uc?id=1QekTM8f2K_Nmszsihe6wH0cLpS0qEbCX`
export const ourYoungPeople = `https://drive.google.com/uc?id=1ILeORKwDvsbQoTPqo5jFEWf-f1tL9Anh`
export const storyOne = `https://drive.google.com/uc?id=1OFnxPGBm8JrwyTVpPlN10xaLGfF7T8Oq`
export const storyTwo = `https://drive.google.com/uc?id=1LQk_7_Iu3uudQ05rh2qjInARqgqKdXMf.svg`
export const storyThree = `https://drive.google.com/uc?id=1eNuqA2t4ETarobrylz7_kFWwaNeqGKJp`
export const DeltaIcon = `${imgPath}/delta-logo.svg`
export const placeholder = `${imgPath}/placeholder.png`
export const alert = `${imgPath}/alert.png`
export const notfound = `${imgPath}/notfound.png`
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorBoundry {
  display: flex;
  flex-direction: column;
  height: 30vh;
  align-items: center;
  justify-content: center;
}
.errorBoundry img {
  height: 6rem;
  width: 6rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ErrorBoundry/errorboundry.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,mBAAA;EACA,uBAAA;AACJ;AAAI;EACI,YAAA;EACA,WAAA;AAER","sourcesContent":[".errorBoundry{\n    display: flex;\n    flex-direction: column;\n    height: 30vh;\n    align-items: center;\n    justify-content: center;\n    img{\n        height: 6rem;\n        width: 6rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react"
import { headerBtn } from "../constant"

const HeaderBtn: React.FC = () => {

    return (
        <div className="header-button">
            {
                headerBtn.map((item,index) => <button className={`btn ${item.class}`} key={index} onClick={item.onclick} >{item.text}</button>)
            }
        </div >
    )
}


export default HeaderBtn
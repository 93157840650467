import { useNavigate } from 'react-router-dom'
import CustomButton from '../Button/Button'
import './seeallstories.scss'
import { useStoryContext } from '../../context/StoryContext'
import { useMenuContext } from '../../context/MenuContext'

const SeeAllStories = (props: any) => {
  const navigate = useNavigate()
  const { tags } = props
  const { setActive } = useStoryContext()
  const { menu } = useMenuContext()

  const temp = menu?.find((item) => item?.category === 'our-stories')

  const blogUid = temp?.subCategory[0]?.uid

  const handleClick = () => {
    setActive(blogUid)
    navigate(`/content/${blogUid}`)
  }

  return (
    <div className='see-all-stories'>
      <div className='title'>All posts tagged</div>
      <div className='stories-badge-wrapper'>
        {tags?.map((tag: any) => {
          return <div className='single-story-badge'>{tag}</div>
        })}
      </div>

      <CustomButton title='SEE ALL STORIES' onClick={handleClick} />
    </div>
  )
}

export default SeeAllStories
